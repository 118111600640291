
import { Vue, Component, Prop } from 'vue-property-decorator';
import { addPostApply } from '@/api/post-apply';
import { getUserInfo } from '@/api/user';
import { getFile, uploadFile } from '@/api/attachedFile';

@Component({
  name: 'BoardApplyForm',
})
export default class extends Vue {
  @Prop({ required: true }) private postUid!: string;

  @Prop({ default: null }) private postApply!: any;

  @Prop({ required: true }) private isEnd!: boolean;

  mounted() {
    this.setUserInfo();
  }

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private form = {
    name: '',
    tel: '',
    email: '',
    postUid: this.postUid,
    fileUid: null,
    checkRule: false,
  };

  private fileInfo = null;

  private handleSubmitForm() {
    this.$validator.validate().then((valid: boolean) => {
      if (valid) {
        addPostApply(this.form).then(() => {
          alert('성공적으로 신청되었습니다.');
          this.$emit('apply');
        }).catch(() => {
          alert('신청에 실패했습니다.');
        });
      }
    });
  }

  private setUserInfo() {
    if (!this.postApply) {
      getUserInfo().then((res) => {
        this.form = {
          ...this.form,
          name: res.data.actualName,
          tel: res.data.phone,
          email: res.data.userId,
        };
      });
    } else {
      this.form = {
        ...this.form,
        name: this.postApply.name,
        tel: this.postApply.tel,
        email: this.postApply.email,
        fileUid: this.postApply.fileUid,
      };
      if (this.postApply.fileUid) {
        getFile(this.postApply.fileUid).then((res) => {
          this.fileInfo = res.data;
        });
      }
    }
  }

  private handleChangeFile(e: any) {
    const file = e.target.files[0];
    if (10 * 1024 * 1024 < file.size) {
      alert('최대 10MB 까지 업로드 가능합니다.');
      return;
    }
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    uploadFile('post', formData).then((res) => {
      this.form.fileUid = res.data.uid;
      this.fileInfo = res.data;
    });
  }

  private handleDeleteFile() {
    this.fileInfo = null;
    this.form.fileUid = null;
  }
}
