
import { getPost } from '@/api/post';
import { deletePostApply, getOwnPostApply } from '@/api/post-apply';
import { UserModule } from '@/store/user';
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import ApplyForm from '@/components/apply/form.vue';
import ApplyList from '@/components/apply/index.vue';

@Component({
  name: 'AppSupportDetail',
  components: {
    ApplyForm,
    ApplyList,
  },
})
export default class extends Vue {
  mounted() {
    this.getPost();
  }

  get isAdmin() {
    return UserModule.roles.indexOf('ROLE_ADMIN') > -1;
  }

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  get isLogined() {
    return UserModule.token;
  }

  private post: any = null;

  private postApply: any = null;

  private applyVisible = false;

  private getPost() {
    if (this.$route.params.postUid) {
      getPost(this.$route.params.postUid).then((res) => {
        this.post = res.data;
        if (this.isLogined) {
          this.getPostApply(res.data);
        }
      });
    } else {
      this.$router.push({ name: 'AppSupportList' });
    }
  }

  /* eslint-disable */
  private getPostApply(post: any) {
    const listQuery = {
      page: 0,
      size: 10,
      postUid: post.uid,
    };
    getOwnPostApply(listQuery).then((res) => {
      this.applyVisible = true;
      this.postApply = res.data.content[0];
    });
  }
  /* eslint-enable */

  private isEnd() {
    if (!this.post.dataList[3].inputValue || !this.post.dataList[4].inputValue) return false;
    const today = moment();
    const startDay = moment(this.post.dataList[3].inputValue, 'YYYY-MM-DD');
    const endDay = moment(this.post.dataList[4].inputValue, 'YYYY-MM-DD').hours(23).minutes(59).seconds(59);
    return today.isSameOrAfter(startDay) && today.isSameOrBefore(endDay);
  }

  private handleClickApply() {
    if (this.isLogined) {
      (this.$refs.applyForm as any).handleSubmitForm();
    } else {
      alert('로그인이 필요한 서비스입니다.');
      this.$router.push({ name: 'AppLogin' });
    }
  }

  private handleSuccessApply() {
    this.$router.go(0);
  }

  private handleDeleteApply() {
    if (window.confirm('정말 지원 신청을 취소하시겠습니까?')) {
      deletePostApply(this.postApply.uid).then(() => {
        this.$router.go(0);
      });
    }
  }
}
