import request from '@/utils/request';

const PATH = '/post-apply';

export const getPostApplyList = (params: any) => request({
  url: PATH,
  method: 'get',
  params,
});

export const getOwnPostApply = (params: any) => request({
  url: `${PATH}/own`,
  method: 'get',
  params,
});

export const addPostApply = (data: any) => request({
  url: PATH,
  method: 'post',
  data,
});

export const deletePostApply = (uid: number) => request({
  url: `${PATH}/${uid}`,
  method: 'delete',
});
