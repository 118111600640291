import { UserModule } from '@/store/user';
import axios from 'axios';

/* eslint-disable */
const service = axios.create({
  baseURL: process.env.VUE_APP_COMMON_API, // url = base url + request url
  timeout: 30000,
  // withCredentials: true // send cookies when cross-domain requests
});

// Response interceptors
service.interceptors.response.use(
  (response: any) => {
    const res = response.data;
    if (response.status !== 200) {
      alert(res.message);
      return Promise.reject(new Error(res.message || 'Error'));
    }
    return response;
  },
  (error: any) => {
    const res = error.response;
    if (res.status === 401 && res.data.error === 'invalid_token') {
      UserModule.LogOut();
      window.location.replace('/');
      return;
    }
    // alert(res.data.message);
    return Promise.reject(error);
  },
);

export default service;
