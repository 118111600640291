
import { deletePostApply, getPostApplyList } from '@/api/post-apply';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Pagination from '@/components/board/pagination.vue';

@Component({
  name: 'BoardApplyList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private postUid!: string;

  mounted() {
    this.getApplyList();
  }

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private listQuery: any = {
    page: 0,
    size: 10,
    postUid: this.postUid,
  };

  private applyList: any = [];

  private totalElements = 0;

  private totalPages = 0;

  private getApplyList() {
    getPostApplyList(this.listQuery).then((res) => {
      if (this.listQuery.page > 0 && res.data.content.length === 0) {
        this.listQuery.page -= 1;
        this.getApplyList();
      } else {
        this.applyList = res.data.content;
        this.totalElements = res.data.totalElements;
        this.totalPages = res.data.totalPages;
      }
    });
  }

  private handleDeleteApply(apply: any) {
    if (window.confirm(`정말 ${apply.name}(${apply.email})의 지원을 삭제하시겠습니까?`)) {
      deletePostApply(apply.uid).then(() => {
        this.getApplyList();
      });
    }
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getApplyList();
  }
}
